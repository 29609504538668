<template>
  <div class="input-category" v-if="values.length > 0">
    <div :class="['radio-input', disabled && 'radio-input--disabled']">
      <div v-for="value of values" :key="value.id">
        <div v-if="value.depth == 1 && value.id != categoryId" class="radio-input__wrap">
          <div class="radio-input__radio-container first">
            <input
              type="radio"
              :id="value.id"
              v-model="setValue[id]"
              :value="value.name"
              :disabled="disabled"
            />
            <div
              @click="setValue[id] = value.id"
              :class="[
                'radio-input__radio',
                setValue[id] === value.id && 'radio-input__radio--checked',
              ]"
            ></div>
            <label @click="handleOpen(value.id)" class="radio-input__parent-label" :for="value">{{
              value.name
            }}</label>
          </div>
          <div
            v-show="value.children.length > 0"
            @click="handleOpen(value.id)"
            :class="['radio-input__parent-open', show == value.id ? 'open' : '']"
          ></div>
          <template v-if="value.children.length > 0">
            <div v-show="show == value.id" class="radio-input__children">
              <div v-for="item of value.children" :key="item.id">
                <div
                  v-if="item.id != categoryId"
                  @click="setValue[id] = item.id"
                  class="radio-input__radio-container second"
                >
                  <input
                    type="radio"
                    :id="item.id"
                    v-model="setValue[id]"
                    :value="item.name"
                    :disabled="disabled"
                  />
                  <div
                    :class="[
                      'radio-input__radio',
                      setValue[id] === item.id && 'radio-input__radio--checked',
                    ]"
                  ></div>
                  <label :for="value">{{ item.name }}</label>
                </div>
                <div class="radio-input__radio-padding" v-for="i of item.children" :key="i.id">
                  <div
                    v-if="i.id != categoryId"
                    @click="setValue[id] = i.id"
                    class="radio-input__radio-container third"
                  >
                    <input
                      type="radio"
                      :id="i.id"
                      v-model="setValue[id]"
                      :value="i.name"
                      :disabled="disabled"
                    />
                    <div
                      :class="[
                        'radio-input__radio',
                        setValue[id] === i.id && 'radio-input__radio--checked',
                      ]"
                    ></div>
                    <label :for="value">{{ i.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id">
        {{ error }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputCategory",
  data() {
    return {
      show: null,
    };
  },
  watch: {
    values: function (newVal, oldVal) {
      this.handleOpenChooseCategory();
    },
  },
  methods: {
    handleOpen(id) {
      if (this.show != id) {
        this.show = id;
      } else {
        this.show = null;
      }
    },
    handleOpenChooseCategory() {
      if (this.setValue[this.id] != null) {
        this.show = this.values.find((item) => item.id === this.setValue[this.id]).parentId;
      }
    },
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    categoryId: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default: "name",
    },
    value: {
      type: String,
      default: "id",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-input {
  display: flex;
  flex-direction: column;
  input {
    display: none;
  }
  &__radio-container {
    display: flex;
    padding-bottom: 15px;

    label {
      font-weight: bold;
      margin-bottom: 0;
    }

    &.first {
      label {
        font-size: 20px;
      }
    }

    &.second {
      label {
        font-size: 18px;
      }
    }

    &.third {
      label {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  &__radio-padding {
    padding-left: 30px;
  }
  &__children {
    padding-left: 25px;
  }
  &__parent-open {
    width: 20px;
    height: 12px;
    background: #f5f8fa
      url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.50495 5.78413L0.205241 1.25827C-0.0684138 0.970375 -0.0684138 0.503596 0.205241 0.215836C0.478652 -0.0719461 0.922098 -0.071946 1.19549 0.215837L5.00007 4.22052L8.80452 0.215953C9.07805 -0.0718292 9.52145 -0.0718292 9.79486 0.215953C10.0684 0.503736 10.0684 0.970492 9.79486 1.25839L5.49508 5.78425C5.35831 5.92814 5.17925 6 5.00009 6C4.82085 6 4.64165 5.928 4.50495 5.78413Z' fill='%23919191'/%3E%3C/svg%3E%0A")
      no-repeat center / cover;
    position: absolute;
    right: 20px;
    top: -4px;
    transform: translateY(50%);
    content: "";
    display: block;
    transition: 0.2s ease-in;
    cursor: pointer;

    &.open {
      transform: rotate(180deg);
    }
  }
  &__wrap {
    position: relative;
  }
  &__radio {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid #131313;
    box-sizing: border-box;
    border-radius: 50px;
    cursor: pointer;

    &--checked {
      background: #fff;
      position: relative;
      display: flex;
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background: $mainColor;
        margin: auto;
        border-radius: 50px;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .first & {
      min-width: 22px;
      min-height: 22px;
      max-width: 22px;
      max-height: 22px;
    }

    .second & {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
    }

    .third & {
      min-width: 18px;
      min-height: 18px;
      max-width: 18px;
      max-height: 18px;
    }
  }
  &--disabled {
    label {
      color: red;
      margin-left: 20px;
    }
  }
  label {
    margin-left: 20px;
    color: black;
    cursor: pointer;
  }
}
.input-category {
  padding-top: 15px;
}
</style>
