<template>
  <div class="search-input">
    <form @submit.prevent="search(value)">
      <label :for="label">
        <input
          v-if="!setValue"
          :id="label"
          v-model="value"
          type="text"
          :placeholder="placeholder"
        />
        <input v-else :id="label" v-model="setValue[id]" type="text" :placeholder="placeholder" />
        <div class="search-input__button" @click="search(value)"><span>Ok</span></div>
        <div
          @click="handleClear"
          v-if="setValue ? setValue[id] != null && setValue[id].length > 0 : value.length > 0"
          class="search-input__clear"
        >
          <IconCancel />
        </div>
      </label>
    </form>
  </div>
</template>
<script>
import IconCancel from "~/assets/images/icons/cancel-icon.svg";
export default {
  data() {
    return {
      value: "",
    };
  },
  name: "InputSearch",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    search: {
      type: [Function, Boolean],
      default: false,
    },
    setValue: {
      type: [Object, Boolean],
      default: false,
    },
  },
  components: {
    IconCancel,
  },
  methods: {
    handleClear() {
      if (this.setValue) {
        this.setValue[this.id] = null;
        this.search();
      } else {
        this.value = "";
        this.search(this.value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search-input {
  input {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    height: 36px;
    border: 1px solid $mainColor;
    box-sizing: border-box;
    outline: none;
    font-size: 12px;
    padding: 17px 0 16px 16px;
    &::placeholder {
      color: grey;
      padding: 16px 0px;
      font-size: 12px;
    }
  }
  label {
    display: flex;
    position: relative;
  }
  &__button {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 13px;
    height: 100%;
    background: $mainSecondColor;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    span {
      height: fit-content;
      font-size: 12px;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      path {
        fill: $mainDisabled;
      }
    }
  }
}
</style>
